import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, RouterModule } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService, UserType } from '../../../../../../services/auth.service';
import { UserModel } from '../../../../../../services/user-model';
import { XtrasService } from '../../../../../../services/xtras.service';
import { Menu } from '../../../../../../interfaces/interfaces';
import { ButtonCapYeiComponent } from '../../../../../../components/button/button.component';
import { getEnvironment } from '../../../../../../environment/environment';


@Component({
  selector: 'app-sidebar-1',
  templateUrl: './sidebar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    RouterModule,
    ButtonCapYeiComponent,
  ],
})
export class Sidebar1Component implements OnInit {

  private unsubscribe: Subscription[] = [];

  user$: Observable<UserType>;

  env = getEnvironment();

  @Input() menu: Menu[] = [];

  constructor(
    private router: Router,
    public userModel: UserModel,
    public xServices: XtrasService,
    public authService: AuthService,
  ) {
    this.user$ = this.authService.user$;

  }

  ngOnInit() {
    
  }

  irInicio() {
    this.router.navigateByUrl('/');
  }

  goToPage(url: any) {
    this.router.navigateByUrl('/'+url);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  isLinkActive( url:string ) {
    var actualUrl = this.router.url;
    var lastWord  = url.substr(url.lastIndexOf("/")+1);
    var lastChar  = lastWord.charAt(lastWord.length-1);
    var lastOtherWord = null;
    if(lastWord == 'city' || lastWord == 'cities') {
      lastWord = 'city';
    }
    if( lastChar == 's' ) {
      lastOtherWord = lastWord.substring(0, lastWord.length-1);
    }
    if((actualUrl).toString().includes('/form/') || (actualUrl).toString().includes('/list/')) {
      if(actualUrl.split('/')[3] == lastWord || actualUrl.split('/')[3] == lastOtherWord) return true;
      return false;
    }
    return ((actualUrl).toString()).includes(lastWord) || ((actualUrl).toString()).includes(lastOtherWord!);
  }

  escapeRegExpMatch(s: any) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  isExactMatch(str: any, match: any) {
    return new RegExp(`\\b${this.escapeRegExpMatch(match)}\\b`).test(str);
  }
}
