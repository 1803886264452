<ng-template #header>
    @switch (env.templateType) {
        @case ('template-2') {
            <app-header-2 [menu]="menu" class="sticky top-0 w-full z-[2]"></app-header-2>
        }
        @case ('template-3') {
            <app-header-3 [menu]="menu" class="sticky top-0 w-full z-[2]"></app-header-3>
        }
        @case ('template-4') {
            <app-header-4 [menu]="menu" class="sticky top-0 w-full z-[2]"></app-header-4>
        }
        @default {
            <app-header-1 [menu]="menu" class="sticky top-0 w-full z-[2]"></app-header-1>
        }
    }
</ng-template>

<ng-template #sidebar>
    @switch (env.templateType) {
        @case ('template-2') {
            <app-sidebar-2 [menu]="menu"></app-sidebar-2>
        }
        @case ('template-3') {
            <app-sidebar-3 [menu]="menu"></app-sidebar-3>
        }
        @case ('template-4') {
            <app-sidebar-4 [menu]="menu"></app-sidebar-4>
        }
        @default {
            <app-sidebar-1 [menu]="menu"></app-sidebar-1>
        }
    }
</ng-template>

<ng-template #footer>
    @switch (env.templateType) {
        @case ('template-2') {
            <app-footer-2></app-footer-2>
        }
        @case ('template-3') {
            <app-footer-3></app-footer-3>
        }
        @case ('template-4') {
            <app-footer-4></app-footer-4>
        }
        @default {
            <app-footer-1></app-footer-1>
        }
    }
</ng-template>




@if (env.disclaimer) {
    <app-disclaimer-1></app-disclaimer-1>
}
@if(env.header) {
    <ng-container *ngTemplateOutlet="header"></ng-container>
}
@if(env.sidebar) {
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
}

<main id="cttMain">
    <router-outlet class="contents"></router-outlet> 
</main>

@if(env.preFooter) {
    <app-footer-app-1></app-footer-app-1>
}
@if(env.footer) {
    <ng-container *ngTemplateOutlet="footer"></ng-container>
}