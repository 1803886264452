<footer class="sm:px-[3rem] px-4 pt-[3rem] bg-secondary">
	<div class="w-[1260px] max-w-full block mx-auto text-center relative before:absolute before:top-1/2 before:-translate-y-1/2 before:left-0 before:h-[1px] before:w-full before:bg-gray-100 px-4 mb-8">
		<h3 class="bg-secondary text-white relative inline-block px-2 text-xl font-semibold">Auspiciadores</h3>
	</div>
	<div class="w-[1260px] max-w-full mx-auto pb-[3rem]">
		<div class="w-full flex flex-wrap justify-center items-center gap-4 pb-4">
			@for (item of lineImages; track $index) {
				@if(item.img) {
					<img [src]="item.img" [alt]="item.name">
				}
			}
		</div>
	</div>
	<div class="w-[1440px] max-w-full mx-auto pt-4 lg:px-5 px-3 text-center">
		<div class="w-[900px] max-w-full mx-auto pb-[3rem]">
			<nav class="flex flex-wrap justify-between">
				<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-center text-white">
					<li class="block mb-4">
						<h5 class="text-lg font-semibold">Soporte Todotix - Abonos</h5>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center">
							<i class="fa-light fa-phone mr-1"></i> <span>Celular: <a href="tel:+59169777799" target="_blank">69777799</a></span>
						</p>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center">
							<i class="fa-brands fa-whatsapp mr-1"></i> <span>WhatsApp: <a href="https://api.whatsapp.com/send?phone=59169777799" target="_blank">69777799</a></span>
						</p>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center">
							<i class="fa-light fa-envelope mr-1"></i> <span>E-mail: <a href="mailto:soporte@todotix.com" target="_blank" [innerText]="'soporte@todotix.com'"></a></span>
						</p>
					</li>
				</ul>

				<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-center text-white">
					<li class="block mb-4">
						<h5 class="text-lg font-semibold">Páginas</h5>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a routerLink="/shop">Abonos</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a routerLink="/account">Mi cuenta</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a routerLink="/pending-payments">Mis Pagos</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a (click)="openTerms()" class="cursor-pointer">Términos y condiciones</a></p>
					</li>
				</ul>

				<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-center text-white">
					<li class="block mb-4">
						<h5 class="text-lg font-semibold">Redes sociales</h5>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center text-[#4267b2]">
							<i class="fa-brands fa-facebook-f mr-1"></i> <a href="https://www.facebook.com/clubthestrongestoficial" target="_blank">Facebook</a>
						</p>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center text-[#00adee]">
							<i class="fa-brands fa-twitter mr-1"></i> <a href="https://twitter.com/ClubStrongest" target="_blank">Twitter</a>
						</p>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center text-[#9f47b4]">
							<i class="fa-brands fa-instagram mr-1"></i> <a href="https://www.instagram.com/clubstrongest/?hl=es-la" target="_blank">Instagram</a>
						</p>
					</li>
				</ul>
			</nav>
		</div>
	</div>
	<!-- <div class="w-[1024px] max-w-full mx-auto p-4 lg:px-5 px-3 text-center border-t border-[#737373]">
		<p class="font-medium text-sm text-gray-400">&copy; Copyright {{ year ?? currentYear }} {{ title ?? 'Solunes Digital' }} | Todos los derechos reservados.</p>
	</div> -->
</footer>
