import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { XtrasService } from '../../../../../../services/xtras.service';
import { AuthService } from '../../../../../../services/auth.service';
import { UserModel } from '../../../../../../services/user-model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCapYeiComponent } from '../../../../../../components/button/button.component';
import { DropdownMenuCapYeiComponent } from '../../../../../../components/dropdown-menu/drowdown-menu.component';
import { HttpClientProvider } from '../../../../../../services/http-client';
import { LoadingService } from '../../../../../../services/loading.service';
import { SettingsProvider } from '../../../../../../services/settings';

@Component({
  selector: 'app-disclaimer-1',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    DropdownMenuCapYeiComponent,
  ]
})
export class Disclaimer1Component implements OnInit {

  private unsubscribe: Subscription[] = [];

  dataGoal: any = null;

  constructor(
    public xServices: XtrasService,
    public authService: AuthService,
    public userModel: UserModel,
    private http: HttpClientProvider,
    private load: LoadingService,
  ) {
  }

  ngOnInit() {
    this.dataGoal = null;
    const subscr = this.http.getRequest(SettingsProvider.getUrl(`goal`)).subscribe((result: any) => {
      if(result['status']&&result['data']) {
        this.dataGoal = result['data']
      }
    }, () => {
    });
    this.unsubscribe.push(subscr);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
