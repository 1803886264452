<div class="bg__sidebar_left hidden fixed top-0 left-0 w-full h-full z-[100] bg-[rgba(0,0,0,0.5)]"></div>

<div class="sidebar_left block h-full overflow-hidden bg-white drop_shadow fixed top-0 left-0 transition-all duration-300 ease-in-out z-[101] w-[300px] -translate-x-[100%]">
	<div class="w-full block h-full lg:bg-[#f7f7f7]">
		<div class="w-full h-full overflow-hidden lg:bg-white pb-6">
			<div class="content__scroll-sidebar max-h-full h-full w-full overflow-x-hidden overflow-y-auto" data-scrollbar>
				<div class="w-[150px] my-5 max-w-full block mx-auto" routerDirection="root" href="/">
					<img [src]="env.imgLogo" [alt]="env.title" class="mx-auto block py-4">
				</div>
				<ul class="h-auto list-none flex flex-col gap-1">
					<ng-container *ngFor="let item of menu">
						<li class="px-4 pt-4">
							@if (item.submenu) {
								<h5 class="font__title font-semibold uppercase m-0">{{ item.name }}</h5>
							} @else {
								@if(item.external) {
									<a [href]="item.url" target="_blank" class="font__title font-semibold uppercase text-primary underline">{{ item.name }}</a>
								} @else {
									<a [routerLink]="item.url" class="font__title font-semibold uppercase text-primary underline">{{ item.name }}</a>
								}
							}
						</li>
						<ng-container *ngFor="let itemMenu of (item?.submenu ?? [])">
							<li class="block px-4">
								<ng-template #subItemMenu>
									<div class="w-full text-center">
										<img [src]="itemMenu.img" alt="icon" class="max-w-full">
									</div>
									<div class="w-full">
										<h5 class="font-medium text-base">{{ itemMenu.name }}</h5>
										<p *ngIf="itemMenu.desc" class="font-light text-sm">{{ itemMenu.desc }}</p>	
									</div>
								</ng-template>
								@if(itemMenu.external) {
									<a [href]="itemMenu.url" target="_blank" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
										<ng-container [ngTemplateOutlet]="subItemMenu"></ng-container>
									</a>
								} @else {
									<a [routerLink]="itemMenu.url" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
										<ng-container [ngTemplateOutlet]="subItemMenu"></ng-container>
									</a>
								}
							</li>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!authService.getAuth();">
						<div class="flex flex-col gap-2 p-4">
							<a routerLink="/auth/login">
								<button-cy type="button" addClass="w-full font__title uppercase" label="Iniciar sesión" />
							</a>
							<a routerLink="/auth/register">
								<button-cy type="button" addClass="w-full font__title uppercase btn__second" label="Registrarse" />
							</a>
						</div>
					</ng-container>
				</ul>
			</div>
		</div>
	</div>
</div>
