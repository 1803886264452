import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
    selector: 'dropdown-menu-cy',
    template: `
        <div class="dropdown_menu_cy-item" [ngClass]="isOpen ? 'is_open' : 'is_closed'">
            <button class="dropdown_menu_cy-action" (click)="toggleMenu()">
                @if(!customTitle) {
                    {{ title }} <small><i class="fa-light fa-angle-down"></i></small>
                } @else {
                    <ng-container *ngTemplateOutlet="title"></ng-container>
                }
            </button>
            <div class="dropdown_menu_cy-content position__{{ position }}">
                <div class="dropdown_menu_cy-content-int content__scroll-sidebar" [@openClose]="isOpen ? 'open' : 'closed'">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    standalone: true,
    imports: [
        CommonModule,
    ],
    animations: [
        trigger('openClose', [
            state('open', style({
                // transform: 'translateY(0)',
                // transformOrigin: 'top',
                // opacity: 1,
                display: 'block',
            })),
            state('closed', style({
                // transform: 'translateY(-50%)',
                // transformOrigin: 'top',
                // opacity: 0.5,
                display: 'none',
            })),
            transition('closed => open', [
                // style({
                //     display: 'block',
                // }),
                animate('0.2s')
            ]),
            transition('open => closed', [
                animate('0.2s'),
            ]),
        ]),
    ],
})
export class DropdownMenuCapYeiComponent {
    @Input() isOpen:boolean = false;
    @Input() title: any;
    @Input() customTitle: boolean = false;
    @Input() position: 'center' | 'left' | 'right' = 'center';

    constructor(private _eref: ElementRef) { }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.isOpen = false;
        } else if (event.target.tagName.toLowerCase() === 'a') {
            this.isOpen = false;
        }
    }

    toggleMenu() {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            const links = this._eref.nativeElement.querySelectorAll('a');
            links.forEach((link:any) => {
                link.addEventListener('click', () => this.isOpen = false);
            });
        }
    }
}