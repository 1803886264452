<header class="sm:block hidden w-full bg-secondary 2xl:p-0 py-0 px-5 relative z-[2]">
	<nav class="flex items-center justify-between w-[1440px] max-w-full mx-auto">
		<div class="md:p-0 py-3 px-4 flex flex-wrap gap-8 items-center">
			<ul class="flex flex-wrap items-center gap-4">
				<li>
					<a href="https://clubbolivar.com/" target="_blank" class="font__title uppercase text-white">Club Bolívar</a>
				</li>
				<li class="h-[20px] w-[1px] bg-white"></li>
				<li>
					<span class="font__title uppercase text-primary">Socio Centenario</span>
				</li>
				<li class="h-[20px] w-[1px] bg-white"></li>
				<li>
					<a routerLink="/my-session" target="_blank" class="font__title uppercase text-white">Bolívar Store</a>
				</li>
			</ul>
		</div>

		<div class="md:flex hidden flex-wrap gap-8 items-center">
			<div *ngIf="dataGoal" class="w-auto text-white text-center">
				<h5 class="font__title uppercase text-lg font-medium"><span>{{ dataGoal.title }}</span></h5>
				<h5 class="font__title uppercase text-lg font-medium"><span>{{ dataGoal.subtitle }}</span></h5>
			</div>
			<a routerLink="/" class="w-[140px] h-[60px] relative overflow-hidden text-primary hover:text-white">
				<img src="/assets/img/general/become-a-member.gif" alt="Hazte Socio" class="absolute left-0 right-0 top-0 bottom-0 scale-[2] opacity-20">
				<span class="w-full text-center absolute left-1/2 top-[150%] -translate-x-1/2 -translate-y-1/2 animate-[textUp_1s_3s_forwards] transition-transform font-medium font__title uppercase">¡Hazte Socio!</span>
			</a>
		</div>
	</nav>
</header>