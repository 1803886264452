import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TermsModalComponent } from '../../../../../../components/terms-modal/terms-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getEnvironment } from '../../../../../../environment/environment';

@Component({
  selector: 'app-footer-4',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TermsModalComponent
  ]
})


export class Footer4Component implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;
  env = getEnvironment();

  line1Images = [
    { img: `assets/img/footer/line-1/synergy.png`, name: 'Synergy' },
    { img: `assets/img/footer/line-1/tigo.png`, name: 'Tigo' },
    { img: `assets/img/footer/line-1/boa.png`, name: 'Boa' },
    { img: `assets/img/footer/line-1/campero.png`, name: 'Campero' },
    { img: `assets/img/footer/line-1/coboce.png`, name: 'Coboce' },
    { img: `assets/img/footer/line-1/am.png`, name: 'ArcelorMittal' },
  ];
  line2Images = [
    { img: `assets/img/footer/line-2/taquina.png`, name: 'Taquiña' },
    { img: `assets/img/footer/line-2/atb.png`, name: 'ATB' },
    { img: `assets/img/footer/line-2/alianza.png`, name: 'Alianza' },
    { img: `assets/img/footer/line-2/4k.png`, name: '4K' },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }
    
  ngOnInit() {
    this.year = this.env.yearProyect;
    this.title = this.env.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  openTerms() {
    const dialogRef = this.dialog.open(TermsModalComponent, {
      width: '800px',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);

    });
  }

}
