<div class="bg-primary">
	<div class="container_cy">
		<div class="flex flex-wrap items-center justify-center p-4">
			<h3 class="uppercase font__title font-semibold md:text-2xl text-xl text-white text-center">¡Descarga la aplicación del {{ env.title }}!</h3>
			<span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
			<div class="flex">
				<a *ngIf="playstore" [href]="playstore" target="_blank" title="Disponible en Google Play">
					<img src="/assets/img/general/googleplay.png" alt="Disponible en Google Play">
				</a>
				<a *ngIf="appstore" [href]="appstore" target="_blank" title="Descárgalo en App Store">
					<img src="/assets/img/general/appstore.png" alt="Descárgalo en App Store">
				</a>
			</div>
		</div>
	</div>
</div>