import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TermsModalComponent } from '../../../../../../components/terms-modal/terms-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getEnvironment } from '../../../../../../environment/environment';

@Component({
  selector: 'app-footer-2',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TermsModalComponent
  ]
})


export class Footer2Component implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;

  env = getEnvironment();

  lineImages = [
    { img: `assets/img/footer/pacena.png`, name: 'Paceña' },
    { img: `assets/img/footer/chery.png`, name: 'CHERY' },
    { img: `assets/img/footer/alianza.png`, name: 'Alianza' },
    { img: `assets/img/footer/bago.png`, name: 'Bagó' },
    { img: `assets/img/footer/boa.png`, name: 'Boa' },
    { img: `assets/img/footer/sinteplast.png`, name: 'Sinteplast' },
    { img: `assets/img/footer/vs.png`, name: 'Villa Santa' },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }
    
  ngOnInit() {
    this.year = this.env.yearProyect;
    this.title = this.env.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  openTerms() {
    const dialogRef = this.dialog.open(TermsModalComponent, {
      width: '800px',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);

    });
  }

}
